<template>
 <div class="cols-12">
   <v-card class="pl-3 pr-3 pt-2  mb-2" min-width="100%">
      <modal />
      <v-row>
          <v-col cols="12" class="pb-0 pt-2">
            <!-- Promotor -->
              <v-row>
                <v-col dense class="pb-0 pt-0 mt-1" sm="6" md="2">
                    <autoCompleteEntities :entityProps.sync="entityId" :typesEntities="[3 , 4]" :inativos="false" :label="'Código ou Nome'" />
                </v-col>
                <v-col class="pb-0 pt-0 mt-0 valor" cols="12" sm="6" md="2">
                  <vmoney label="Valor"  v-model="value" :options="{ locale: 'pt-BR', prefix: 'R$ ', suffix: '', length: 11, precision: 2 }"/>
                </v-col>
                <!-- <v-col class="pb-0 pt-0 mt-0 valor" sm="6" md="2">
                  <v-select v-model="typeParams" :items="['Débito', 'Crédito']" label="Tipo Lancamento" ></v-select>
                </v-col> -->

                <v-col class="d-flex flex-nowrap buscar pb-3 pt-0 mt-1" col="12" xs="12" sm="6">
                  <FiltroRangeDate
                    v-model="datas"
                    slot="btnSelect"
                    class="d-flex justify-start filtro-range"
                    :value="{ start: '', end: ''}"
                    :button="false"
                    :labelBtn="'Selecionar Data'"
                    :actionLabels="{ apply: 'OK' }"
                  />
                  <v-btn class="ml-3 pt-2 pb-2 br-btn" color="success" @click="filtrarLancamentos()"> Buscar</v-btn>
                
                </v-col>
              </v-row>
          </v-col>
          <!-- <v-col class="col-4 pt-0">
            <v-btn :loading="loading" :color="variables.colorSuccess" @click.prevent="openModal" class="br-btn  br-btn-filtro mr-2"> Novo </v-btn>
           <v-btn :loading="loading" :color="variables.colorPrimary" class="br-btn br-btn-filtro" @click="eventBuscar">Buscar</v-btn>
          </v-col> -->

      </v-row>
    </v-card>
 </div>
</template>

<script>
// eslint-disable-next-line
import { errorSnackbar, successSnackbar, currency } from '@/core/service/utils'
import Events from '@/core/service/events'
import { mapActions, mapGetters } from 'vuex'
import modalCadastro from '@/views/cadastros/entities/mixins/modalCadastro'
import moment from 'moment'
import { mask } from 'vue-the-mask'
export default {
  name: 'FiltroPreCaixa',
  mixins: [modalCadastro],
  directives: {mask},

  components:{
    autoCompleteEntities: () => import('./autoCompleteEntities'),
    FiltroRangeDate: () => import('@/views/components/filtroRangeDate'),
    vmoney: () => import('@/views/components/vMoney'),
    modal: () => import('./modal')
  },
  data: () => ({
    datetime: new Date(),
    datas: {start: moment().format() , end: moment().format()},
    format: 'YYYY-MM-DD',
    entityId: { value : undefined },
    value: undefined,
    typeParams: undefined
  }),
  computed: {
    ...mapGetters('preCaixa', ['filtro'])
  },
  mounted () {

  },
  watch: {
   
  },
  methods: {
    ...mapActions('lancamentoFinanceiro', ['getItens']),
    ...mapActions('preCaixa', ['getEstabelecimentos', 'filterEntities']),
    eventBuscar () {
        this.$emit('buscar')
     },
     openModal () {
        Events.$emit('modalCadastro::precaixa', true)
     },

    filtrarLancamentos () {
      const vm = this
      Events.$emit('table::loadPage', true)
      const obj = {
        '_addlFilter': {
          'pendingOperation:created_>=': moment(vm.datas.start).format('YYYY-MM-DD [00:00:00]'),
          'pendingOperation:created_<=': moment(vm.datas.end).format('YYYY-MM-DD [23:59:59]'),
          'Entities:cod_=': vm.entityId.value,
          'pendingOperation:value_=': vm.value < 0.1 ? undefined : vm.value,
          // 'pendingOperation:params.type_=': vm.typeParams
        }
      }
      this.$emit('input', obj)
      this.getItens(obj).then(result => {
        if (result.data.totalItens < 1) errorSnackbar('Não há dados disponíveis')          
      })
      .catch(err => {
        errorSnackbar(err.error)
      })
      .finally(() => {
        Events.$emit('table::loadPage', false)
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/components/modal-cadastro';
@import '~@//assets/styles/components/formularios';

.datas-input{
  margin-top: 28px
}
.valor > .v-input {
  padding-top: 0;

}
.valor::v-deep {
  .v-text-field {
    padding: 0 !important;
  }
  position: relative;
  top: 1px;
  .v-text-field {
    margin-top: 0;
    padding-top: 0;
  }
}
.br-btn {
  font-size: 14px !important;
}
.box-table .v-btn{
  height: 40px !important;
}
.title {
  padding: 0;
  color: #1cb4ff;
}
</style>
